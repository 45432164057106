<template>
  <div id="authorize">
    <img src="../assets/logo.png" alt/>
    <p class="title">请先授权</p>
    <p class="tips">允许我们获取您的头像、昵称等基本信息</p>
    <div class="btn" @click="getCode">授权登录</div>
  </div>
</template>

<script>
  import {getMerchId} from "../api/requist";

  export default {
    components: {},
    props: [],
    data() {
      return {};
    },
    created() {
    },
    mounted() {
      if (this.getUrlCode().code) {
        this.getMerchIdFn(this.getUrlCode().code);
      }
    },
    watch: {},
    computed: {},
    beforeRouteEnter(to, from, next) {
      if (localStorage.getItem("merch_id")) {
        next("/index");
      } else {
        next();
      }
    },
    methods: {
      getMerchIdFn(code) {
        let that = this;
        let formData = new FormData();
        formData.append("code", code);
        return getMerchId(formData)
          .then(res => {
            if (res.code === 200) {
              localStorage.setItem("merch_id", res.data.id);
              that.$router.replace({
                name: "orders"
              });
            } else {
              that.$router.push({
                path: "/storeSettled"
              });
            }
          })
          .catch(() => {
          });
      },

      getUrlCode() {
        // 截取url中的code方法
        var url = location.search;
        var theRequest = {};
        if (url.indexOf("?") !== -1) {
          var str = url.substr(1);
          var strs = str.split("&");
          for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
          }
        }
        return theRequest;
      },

      getCode() {
        // 非静默授权，第一次有弹框
        var code = "";
        var local = window.location.href; // 获取页面url
        var appid = this.$root.wxAppId;
        code = this.getUrlCode().code; // 截取code
        if (code == null || code === "") {
          // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
        } else {
          this.getMerchIdFn(code);
        }
      }
    }
  };
</script>
<style lang="scss">
  @import '../common/css/theme.scss';

  #authorize {
    -moz-box-sizing: border-box; /* Firefox */
    -webkit-box-sizing: border-box; /* Safari */
    box-sizing: border-box;
    min-height: 100vh;
    background: rgba(245, 247, 250, 1);
    padding-top: 33.07* 3.75px;

    img {
      display: block;
      margin: 0 auto 4.8* 3.75px;
      height: 80px;
      width: 80px;
    }

    .title {
      font-size: 4.27* 3.75px;
      color: #606266;
      text-align: center;
      margin-top: 34px;
      margin-bottom: 2.13* 3.75px;
    }

    .tips {
      color: rgba(144, 147, 153, 1);
      font-size: 3.73* 3.75px;
      text-align: center;
      margin-bottom: 6.4* 3.75px;
    }

    .btn {
      margin: 0 16px;
      background: $theme-color;
      border-radius: 1* 3.75px;
      font-size: 3.73* 3.75px;
      color: rgba(255, 255, 255, 1);
      padding: 12px;
      text-align: center;
    }
  }
</style>
